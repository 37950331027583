@import 'libs/styles/src/common/common';

@layer shared-component {
  .shared-autocomplete,
  .shared-select {
    font-weight: 600;
    font-size: 1rem;
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .menu-wrapper {
    // display: flex;
    // align-items: center;
    text-align: left;
    user-select: none;
    width: 100%;

    &--wrapping {
      background: #fff;
      border-radius: 25px;
      font-weight: 600;

      // font-size: 18px;
      // white-space: nowrap;
    }

    &--fill-input {
      position: relative;
    }

    &.open {
      border-bottom-right-radius: initial !important;
      border-bottom-left-radius: initial !important;
    }
  }

  .icon {
    padding-right: 8px;
    padding-bottom: 1px;
  }

  .icon--before {
    padding-right: 8px;
  }

  .autocomplete__input-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 25px;
    min-width: fit-content;
    border: 1px solid $light-disabled-grey;

    &.open {
      border-bottom-right-radius: initial !important;
      border-bottom-left-radius: initial !important;
    }
  }

  .autocomplete-input {
    font: inherit;
    line-height: normal;
    text-align: inherit;
    background: none;
    border: none;
    outline: none !important;
    padding: 0.375rem 1.375rem;
    margin: 0;
    color: $blue-alt-1;

    &--wrapping {
      border-radius: 25px;
      width: 100%;
    }

    &::placeholder {
      color: #7e9098;
      font-size: 1rem;
      font-weight: 600;
    }

    &.open {
      border-bottom-right-radius: initial !important;
      border-bottom-left-radius: initial !important;
    }

    &.hide-cursor {
      caret-color: transparent;
      cursor: pointer;
      user-select: none;

      &:disabled {
        cursor: initial;
      }
    }
  }

  .select-toggle {
    font: inherit;
    color: #66797f;
    text-align: inherit;
    background: none;
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
    padding: 0.375rem 1.375rem;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: normal;
    border: 0;

    &.selected {
      color: $blue-alt-1;
    }

    &::after {
      content: ' ';
      border: solid $blue-alt-1;
      border-width: 0 2px 2px 0;
      padding: 3px;
      transition: transform 0.1s linear;
      transform: rotate(45deg);
      margin-bottom: 3px;
      margin-left: 10px;
    }

    &.open {
      &::after {
        transform: rotate(-135deg);
      }
    }

    &.full-width {
      width: 100%;
    }
  }

  .menu {
    color: $blue-alt-1;
    display: block;
    position: absolute; // here
    top: 100%;
    background-color: #fff;
    z-index: 999;
    box-shadow: 0 25px 20px rgb(25 36 67 / 8%);
    border-radius: 25px;
    left: 0;
    right: 0;

    &.open {
      border-top-width: initial !important;
      border-top-style: none !important;
      border-top-color: initial !important;
      border-top-right-radius: initial !important;
      border-top-left-radius: initial !important;
      border: 1px solid $light-disabled-grey;
    }
  }

  .menu-scroll-container {
    max-height: 280px;
    overflow-y: auto;

    &--horizontal-labels {
      display: flex;
      flex-direction: row;
    }

    &.open {
      margin-bottom: 25px;
    }
  }

  .menu-list {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1;

    .menu-item-header {
      padding: 0.5rem 1rem;
      font-weight: 800;
      font-size: 14px;
      color: $dark-blue-text;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .menu-item-hint {
      padding: 0.5rem 1rem;
      font-size: 14px;
      letter-spacing: 1px;
      text-transform: capitalize;
      cursor: pointer;
      color: $blue;

      &:hover {
        text-decoration: underline;
      }
    }

    &.open {
      display: block;
    }
  }

  .menu-item {
    padding: 0.6rem 1rem 0.6rem 1.375rem;
    color: #6a6a7c;
    font-weight: 600;
    font-size: 1rem;

    > input {
      margin-right: 6px;
      margin-left: 0;
    }

    &.highlighted {
      background: $highlight-light-blue;
    }

    strong {
      color: $blue;
      font-weight: 700;
    }

    &.disabled {
      background: $light-gray;
    }
  }

  .clickable-menu-item {
    cursor: pointer;
  }

  .disabled-option {
    color: $light-gray;
    cursor: not-allowed;
    pointer-events: none;
  }

  .label {
    align-self: flex-start;
    text-align: left;
  }

  .button-label {
    align-self: center;
    text-align: left;

    &.placeholder {
      color: #7e9098;
    }
  }
}
