@import 'libs/styles/src/common/common';

@layer shared-component {
  .suggestion {
    font-weight: 600;
    font-size: 13px;
    color: var(--form-label-color, $form-label);

    em {
      font-size: 12px;
    }

    &--experts {
      display: flex;
      flex-direction: row;
    }

    b,
    strong {
      color: $blue;
      font-weight: bold;
    }

    &__image {
      margin-right: 1rem;
    }

    &__specialty {
      font-style: italic;
    }
  }
}
